<template lang="html">
  <div class="content-box">
    <div class="title-area">
      <h2 class="title-b">설정 / 맵관리</h2>
    </div>

    <div class="map-admin mt-s" v-if="mapAdminType==='select'">
      <div class="map-item">
        <h3 class="title-a">관리구역</h3>
        <div class="box-blue">
          <p class="guide-txt" v-if="siteList.length === 0">등록된 관리 구역이 없습니다</p>
          <div class="map-item-unit" v-for="(sItem, idx) in siteList" :key="idx">
            <div v-if="siteUpdateIdx === idx" class="flex items-center border border-solid border-gray-200 bg-white rounded-lg px-3 py-2">
              <input type="text" class="text-sm font-bold mr-5 bg-transparent outline-none" :value="sItem.siteName" @input="changeSiteName" />
              <button class="text-xs font-normal text-red-700 mx-2" @click="updateSiteAction(sItem.siteGuid)">저장</button>
              <button class="text-xs font-normal text-red-700 mx-2" @click="cancelEditSite()">취소</button>
            </div>

            <span v-else class="cursor-pointer" :class="siteGuid === sItem.siteGuid ? 'text-red-700 font-black' : ''" @click="selectSite(sItem.siteGuid)">{{sItem.siteName}}</span>
            

            <div class="btn-item">
              <button type="button" class="btn-modify" @click="updateSite(idx, sItem.siteGuid)"><i class="icon-img ic-txt-list-edit"></i><span class="for-a11y">수정</span></button>
              <button type="button" class="btn-del" @click="deleteSite(sItem.siteName, sItem.siteGuid)"><i class="icdivon-img ic-txt-list-del"></i><span class="for-a11y">삭제</span></button>
            </div>
          </div>
        </div>
        <div class="in-with-add">
          <input class="element-input size-lg" type="text" v-model="siteName" placeholder="관리구역 이름" />
          <button type="button" class="btn-add" @click="registSite()" :disabled="isRegistSite()"><span class="for-a11y">추가</span></button>
        </div>
      </div>

      <div class="map-item">
        <h3 class="title-a">상세위치</h3>
        <div class="box-blue">
          <p class="guide-txt" v-if="siteGuid == '' && mapList.length === 0">관리구역을 선택해주세요</p>
          <p class="guide-txt" v-else-if="siteGuid != '' && mapList.length === 0">등록된 상세 위치가 없습니다</p>

          <div class="map-item-unit" v-for="(mItem, idx) in mapList" :key="idx">
            <div v-if="mapUpdateIdx === idx" class="flex items-center border border-solid border-gray-200 bg-white rounded-lg px-3 py-2">
              <input type="text" class="text-sm font-bold mr-5 bg-transparent outline-none" :value="mItem.mapName" @input="changeMapName" />
              <button class="text-xs font-normal text-red-700 mx-2" @click="updateMapAction(mItem.mapGuid)">저장</button>
              <button class="text-xs font-normal text-red-700 mx-2" @click="cancelEditMap()">취소</button>
            </div>

            <span v-else class="cursor-pointer" :class="mapGuid === mItem.mapGuid ? 'text-red-700 font-black' : ''" @click="selectMap(mItem.mapGuid)">{{mItem.mapName}}</span>
            

            <div class="btn-item">
              <button type="button" class="btn-modify" @click="updateMap(idx, mItem.mapGuid)"><i class="icon-img ic-txt-list-edit"></i><span class="for-a11y">수정</span></button>
              <button type="button" class="btn-del" @click="deleteMap(mItem.mapName, mItem.mapGuid)"><i class="icdivon-img ic-txt-list-del"></i><span class="for-a11y">삭제</span></button>
            </div>
          </div>
        </div>
        <div class="in-with-add">
          <input class="element-input size-lg" type="text" v-model="mapName" placeholder="상세위치 이름" />
          <button type="button" class="btn-add" @click="registMap()" :disabled="isRegistMap()"><span class="for-a11y">추가</span></button>
        </div>
      </div>

      <div class="map-item">
        <h3 class="title-a">맵 등록</h3>
        <div class="box-blue map-add">
          <p v-if="mapGuid == ''" class="guide-txt">상세위치를 선택해주세요</p>


          <div v-else-if="mapGuid != '' && (mapInfo && mapInfo.fileGuid == null)">
            <div class="map-item-unit">
              맵 등록
              <div class="btn-item">
              </div>
            </div>
            <button type="button" class="btn-map-add" @click="dispMapImageRegist()">
              <span class="map-name">{{mapInfo.mapName}}</span>
              <i class="icon-img ic-list-plus"></i>
              <span class="btn-name">등록하기</span>
              <span class="img-data"></span>
            </button>
          </div>

          <div v-else>
            <div class="map-item-unit">
              맵 클릭하여 단말기 배치<span class="count">{{mapInfo.deviceCount}}대</span>
              <div class="btn-item">
                <button type="button" class="btn-modify"><i class="icon-img ic-txt-list-edit" @click="dispMapImageRegist()"></i><span class="for-a11y">수정</span></button>
                <button type="button" class="btn-del"><i class="icon-img ic-txt-list-del" @click="deleteMapImage(mapInfo.mapName, mapInfo.mapGuid)"></i><span class="for-a11y">삭제</span></button>
              </div>
            </div>
            <button type="button" class="btn-map-add" @click="dispAssignDevice()">
              <img :src="mapInfo.fileContent" alt="" />
            </button>
          </div>
        </div>
      </div>
    </div>






    <div class="mt-s" v-else-if="mapAdminType==='assign'">
      <div class="text-left">
        <h2 class="title-a">단말기 배치</h2>
        <div class="flex justify-start items-center mt-3">
          <p class="desc-txt mr-5">{{mapInfo.siteName}} &gt; {{ mapInfo.mapName }}</p>
          <button type="button" @click="addAlertPointer()" class="bg-blue-100  text-xs text-blue-600 px-3 py-2 rounded-2xl border border-dashed border-blue-400">+ 단말기 추가</button>
        </div>

        <div class="w-full relative mt-5">
          <vue-perfect-scrollbar class="overflow-auto min-h-full map-area w-full bg-white flex items-center justify-start" ref="mapScroll">
            <div class="flex relative justify-center items-center" :style="{'min-width': mapImgWidth + 'px','min-height': mapImgHeight + 'px'}" v-resize="setDimensions">
              <img :src="mapInfo.fileContent" alt="" class="select-none" />

              <div 
                v-for="(pItem, idx) in alertPointerList" :key="idx"
                v-dragged="onDragged" class="absolute" :style="{left:pItem.left, top:pItem.top}"
                :id="`map_${idx}`"
              >
                <DropdownAlertAdmin
                  :pointX="pItem.left" 
                  :pointY="pItem.top" 
                  :companyGuid="companyGuid"
                  :siteGuid="siteGuid"
                  :mapGuid="mapGuid"
                  :oldDeviceGuid="pItem.deviceGuid" 
                  :mapImgWidth="mapImgWidth" 
                  :mapImgHeight="mapImgHeight" 
                  :index="idx" 
                  @removeAlertIcon="removeAlertIcon"
                  @refreshAlertIcon="refreshAlertIcon"
                ></DropdownAlertAdmin>
              </div>
            </div>
          </vue-perfect-scrollbar>
        </div>





        <div class="page-btn-area">
          <button type="button" class="element-btn bg-red size-lg" @click="closeAssignDevice()">뒤로</button>
          <!-- <button type="button" class="element-btn size-lg bg-blue" @click="submitAssignDevice()">저장</button> -->
        </div>
      </div>
    </div>

    <div v-else>
      What???
    </div>


    <!-- 맵 이미지 등록 다이얼로그 START -->
    <div class="module-dimmed" v-if="mapInfo!=null && showMapImageDialog">
      <div class="module-dialog">
        <div class="dialog-header">
          <h2 class="dialog-title">지도 {{mapInfo.fileGuid == null ? '등록' : '수정'}}</h2>
        </div>
        <div class="dialog-body">
          <div class="dialog-content">
            <div class="form-group">
              <div class="form-item h-auto">
                <span class="form-label">관리구역</span>
                <div class="form-inp">{{mapInfo.siteName}}</div>
              </div>
              <div class="form-item h-auto">
                <span class="form-label">세부위치</span>
                <div class="form-inp">{{mapInfo.mapName}}</div>
              </div>
              <div class="form-item">
                <span class="form-label">맵 이미지 업로드</span>
                <div class="form-inp">
                  
                  <div v-if="mapFileInfo" class="box-file-upload mt-xs">
                    <div class="text-sm font-bold txt-blue cursor-pointer text-right mt-1 mb-5" @click="retryMapFile()">새로올리기</div>
                    <div class="block text-center mb-2">
                      <div class="flex justify-center items-center">
                        <i class="icon-img ic-map-add-list-icon mr-3"></i>
                      <div>{{ mapFileInfo.name }}</div>
                      </div>
                    </div>
                  </div>
                  
                  <div v-else class="box-file-upload" @dragover="dragover" @dragleave="dragleave" @drop="drop">
                    <input type="file" class="w-px h-px opacity-0 overflow-hidden absolute" @change="registMapFile()" id="mapFile" ref="mapFile" accept=".jpg, .png, .gif" />
                  
                    <label for="mapFile" class="block cursor-pointer">
                      <div class="flex flex-col justify-center items-center">
                        <i class="icon-img ic-m-file-upload"></i>
                        <span class="file-desc">이곳을 클릭하여 파일 업로드 or 파일 드래그</span>
                        <span class="file-name">지원 파일 확장자 *.jpg, *.png, *.gif</span>
                      </div>
                    </label>
                  </div>


                </div>
              </div>
              <div class="form-item mt-0">
                <div class="form-label">
                  기본지도설정
                  <div class="element-checkbox">
                    <input class="radio-input" type="checkbox" id="chk-01" v-model="isMapDefault">
                    <label class="checkbox-label" for="chk-01">선택</label>
                  </div>
                </div>
              </div>
              
              <div class="form-item">
                <span class="form-label">메모</span>
                <div class="form-inp">
                  <input type="text" v-model="mapDescription" class="element-input size-lg" placeholder="메모">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="dialog-footer">
          <button type="button" class="element-btn bg-gray size-lg" @click="closeMapImageRegist()">닫기</button>
          <button v-if="mapInfo.fileGuid == null" type="button" class="element-btn bg-blue size-lg" @click="registMapImage()">등록</button>
          <button v-else type="button" class="element-btn bg-red size-lg" @click="registMapImage()">수정</button>
        </div>
        <button type="button" class="btn-close" @click="closeMapImageRegist()">
          <span class="btn-txt">닫기</span>
          <i class="icon-img ic-popup-close-white"></i>
        </button>
      </div>
    </div>
    <!-- 맵 이미지 등록 다이얼로그 END -->

    <!-- 삭제 다이얼로그 START -->
    <div class="module-dimmed" v-if="showDeleteDialog">
      <div class="module-dialog">
        <div class="dialog-body">
          <div class="dialog-content">
            <p class="text-lg font-bold text-red-700 text-center">다시 한번 확인해 주세요.</p>
            <div class="red-box mt-s">
              <p class="title">체크사항</p>
              <p class="desc-a">
                1. 삭제된 내용은 복구 할 수 없습니다.<br>
                2. 하위 요소들이 모두 삭제 됩니다.<br>
                - 관리구역, 상세위치, 맵, 단말기 정보 등<br>
                3. 단말 설정은 모두 초기화 됩니다.</p>
            </div>
            <p class="confirm-text">관련된 내용이 삭제됩니다.<br>{{deleteStr}}</p>
          </div>
        </div>
        <div class="dialog-footer">
          <button type="button" class="element-btn bg-red size-lg" @click="deleteMapAdminCancel()">취소</button>
          <button type="button" class="element-btn bg-blue size-lg" @click="deleteMapAdminAction()">완료</button>
        </div>
        <button type="button" class="btn-close" @click="deleteMapAdminCancel()">
          <span class="btn-txt">닫기</span>
          <i class="icon-img ic-popup-close-white"></i>
        </button>
      </div>
    </div>
    <!-- 삭제 다이얼로그 END -->

  </div>
</template>

<script>
import { requestApi } from "@/utils/api"
import { mapGetters, mapActions } from "vuex"

import DropdownAlertAdmin from "@/components/elements/DropdownAlertAdmin"

export default {
  created () {
    this.companyGuid = this.getCompanyGuid
    this.getSiteList();
  },
  components: {
    DropdownAlertAdmin,
  },
  computed: {
    ...mapGetters(["getIsOverlay","getCurrentUser","getCompanyGuid"]),
  },
  data () {
    return {
      companyGuid: '',
      
      siteList: [],
      siteName: '',
      siteGuid: '',
      siteUpdateIdx: -1,
      updateSiteName: '',

      mapList:[],
      mapName: '',
      mapGuid: '',
      mapUpdateIdx: -1,
      updateMapName: '',
      mapInfo: {},

      showMapImageDialog: false,
      mapFileInfo: null,
      mapDescription: '',
      isMapDefault: false,

      mapAdminType: 'select',
      mapImgWidth: 1100,
      mapImgHeight: 1000,

      alertPointerList: [],

      showDeleteDialog: false,
      deleteType: null,
      deleteStr: '',
      deleteGuid: '',
    }
  },
  methods: {
    ...mapActions(["chgIsOverlay","chgIsAlert"]),
    setDimensions () {
    },
    async getSiteList () {
      let reqObj = {}
      reqObj.target = `/site/list/${this.companyGuid}`;
      reqObj.method = "get";
      reqObj.params = {};
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.siteList = result.msg.retData
      }
    },
    isRegistSite () {
      let retVal = true;
      if(this.siteName !== '') retVal = false;

      return retVal;
    },
    async registSite() {
      let reqObj = {}
      reqObj.target = `/site/regist`;
      reqObj.method = "post";
      reqObj.params = {};
      reqObj.data = {
        "companyGuid": this.companyGuid,
        "siteDescription": "",
        "siteName": this.siteName
      };
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.siteName = '';
        this.getSiteList()
      }
    },
    
    async updateSiteAction (guid) {
      let reqObj = {}
      reqObj.target = `/site/update`;
      reqObj.method = "put";
      reqObj.params = {};
      reqObj.data = {
        "siteDescription": "",
        "siteGuid": guid,
        "siteName": this.updateSiteName
      };
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.updateSiteName = '';
        this.siteUpdateIdx = -1  
        this.getSiteList()
      }
    },
    changeSiteName (e) {
      this.updateSiteName = e.target.value
    },
    cancelEditSite () {
      this.siteUpdateIdx = -1
    },
    selectSite (guid)  {
      this.siteGuid = guid
      this.mapGuid = '';
      this.mapInfo = {}
      this.mapName = ''
      this.mapUpdateIdx = -1
      this.updateMapName = ''
      this.getMapList();
    },
    updateSite (idx) {
      this.updateSiteName = this.siteList[idx].siteName
      this.siteUpdateIdx = idx
    },

    async getMapList () {
      let reqObj = {}
      reqObj.target = `/map/list/${this.siteGuid}`;
      reqObj.method = "get";
      reqObj.params = {};
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.mapList = result.msg.retData
      }
    },

    isRegistMap () {
      let retVal = true;
      if(this.siteGuid !== '' && this.mapName !== '') retVal = false;

      return retVal;
    },
    async registMap () {
      let reqObj = {}
      reqObj.target = `/map/tempRegist`;
      reqObj.method = "post";
      reqObj.params = {};
      reqObj.data = {
        "mapDescription": "",
        "mapName": this.mapName,
        "siteGuid": this.siteGuid
      };
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.mapName = '';
        this.getMapList()
      }
    },
    changeMapName (e) {
      this.updateMapName = e.target.value
    },
    cancelEditMap () {
      this.mapUpdateIdx = -1
    },
    selectMap (guid) {
      this.mapGuid = guid
      this.getMapInfo()
    },
    updateMap (idx) {
      this.updateMapName = this.mapList[idx].mapName
      this.mapUpdateIdx = idx
    },

    async getMapInfo () {
      let reqObj = {}
      reqObj.target = `/map/info/${this.mapGuid}`;
      reqObj.method = "get";
      reqObj.params = {};
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.mapInfo = result.msg.retData
        this.mapDescription = this.mapInfo.mapDescription
      }
    },
    async updateMapAction (guid) {
      let reqObj = {}
      reqObj.target = `/map/update`;
      reqObj.method = "put";
      reqObj.params = {};
      reqObj.data = {
        "mapDescription": "",
        "mapGuid": guid,
        "mapName": this.updateMapName
      };
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.updateMapName = '';
        this.mapUpdateIdx = -1  
        this.getMapList()
      }
    },

    dispMapImageRegist () {
      this.showMapImageDialog = true
    },
    closeMapImageRegist () {
      this.showMapImageDialog = false
    },

    dragover(event) {
      console.log("dragover", event)
      event.preventDefault();
      if (!event.currentTarget.classList.contains('bg-green-300')) {
        event.currentTarget.classList.remove('bg-gray-100');
        event.currentTarget.classList.add('bg-green-300');
      }
    },
    dragleave(event) {
      console.log("dragleave", event)
      event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('bg-green-300');
    },
    drop(event) {
      console.log("drop", event)
      event.preventDefault();
      this.$refs.mapFile.files = event.dataTransfer.files;
      this.onChange();
      event.currentTarget.classList.add('bg-gray-100');
      event.currentTarget.classList.remove('bg-green-300');
    },
    onChange() {
      this.mapFileInfo = this.$refs.mapFile.files[0];
      
      /**  todo : image update width, height 를 가져 옵니다.**/
      let img = new Image();
      img.onload = function () {
        console.log(this.width, this.height)
      };
      img.src = window.URL.createObjectURL(this.mapFileInfo);
    },
    registMapFile () {
      this.onChange()
    },
    retryMapFile () {
      this.mapFileInfo = null
    },

    async registMapImage () {
      let reqObj = {}
      reqObj.target = `/map/mapImageRegist`;
      reqObj.method = "put";
      reqObj.params = {};
      
      const frm = new FormData();
      frm.append("isDefault", this.isMapDefault ? 'yes' : 'no');
      frm.append("mapDescription", this.mapDescription);
      frm.append("mapGuid", this.mapGuid);
      if(this.mapFileInfo != null) frm.append("mapFileInput", this.mapFileInfo);
      
      reqObj.data = frm;

      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.mapFileInfo = null
        this.mapDescription = '';
        this.isMapDefault = false;

        this.getMapInfo()
        this.showMapImageDialog = false
      }
      
    },

    dispAssignDevice () {
      this.mapAdminType = 'assign'

      this.deviceListAllMap();

      let img = new Image();
      let self = this;
      img.onload = function () {
        // console.log(this.width, this.height)
        self.width = this.width;
        self.height = this.height;
      };
      img.src = this.mapInfo.fileContent;

      setTimeout(() => {
        self.mapImgWidth = this.width
        self.mapImgHeight = this.height
      }, 100)
    },
    closeAssignDevice () {
      this.getMapInfo()
      this.mapAdminType = 'select'
    },
    addAlertPointer () {
      this.alertPointerList.push({left:'100px',top:'100px',deviceGuid:""})
      console.log(this.alertPointerList)
    },
    onDragged({ el, deltaX, deltaY, first, last }) {

      // el.addEventListener('mouseup', (event) => {
      //   console.log(event)
      // })
      // https://github.com/IsraelZablianov/draggable-vue-directive

      console.log(first, last)
      if (first) {
        this.isDragging = true
        return
      }
      if (last) {
        this.isDragging = false
        return
      }
      var l = +window.getComputedStyle(el)['left'].slice(0, -2) || 0
      var t = +window.getComputedStyle(el)['top'].slice(0, -2) || 0
      el.style.left = l + deltaX + 'px'
      el.style.top = t + deltaY + 'px'

      let idx = el.id.substr(4)

      this.alertPointerList[idx].left = el.style.left
      this.alertPointerList[idx].top = el.style.top
    },
    removeAlertIcon (idx) {
      this.alertPointerList.splice(idx, 1)
    },

    refreshAlertIcon () {
      this.deviceListAllMap()
    },


    async deviceListAllMap () {
      let reqObj = {}
      reqObj.target = `/device/listmap/${this.companyGuid}/${this.mapGuid}`;
      reqObj.method = "get";
      reqObj.params = {};
      reqObj.data = {};
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      this.alertPointerList = []
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        for(let i=0; i<result.msg.retData.length; i++) {
          this.alertPointerList.push({left:result.msg.retData[i].pointX,top:result.msg.retData[i].pointY,deviceGuid:result.msg.retData[i].deviceGuid})
        }
      }
    },

    deleteSite (name, guid) {
      // this.$swal({
      //   title: "삭제",
      //   text: `[${name}] 현장을 삭제하시겠습니까?`,
      //   type: "warning",
      //   showCancelButton: true,
      //   confirmButtonColor: "#3085d6",
      //   confirmButtonText: "확인",
      //   cancelButtonText: "취소"
      // }).then((result) => { // <--
      //   if (result.value) { 
      //     this.deleteSiteAction(guid)
      //   }
      // });
      this.deleteType = 'site'
      this.deleteStr = `[${name}] 현장을 삭제하시겠습니까?`
      this.deleteGuid = guid

      this.showDeleteDialog = true;
    },
    deleteMap (name, guid) {
      // this.$swal({
      //   title: "삭제",
      //   text: `[${name}] 상세위치를 삭제하시겠습니까?`,
      //   type: "warning",
      //   showCancelButton: true,
      //   confirmButtonColor: "#3085d6",
      //   confirmButtonText: "확인",
      //   cancelButtonText: "취소"
      // }).then((result) => { // <--
      //   if (result.value) { 
      //     this.deleteMapAction(guid)
      //   }
      // });
      this.deleteType = 'map'
      this.deleteStr = `[${name}] 상세위치를 삭제하시겠습니까?`
      this.deleteGuid = guid

      this.showDeleteDialog = true;
    },
    deleteMapImage (name, guid) {
      this.deleteType = 'mapimage'
      this.deleteStr = `[${name}] 맵을 삭제하시겠습니까?`
      this.deleteGuid = guid

      this.showDeleteDialog = true;
    },

    deleteMapAdminCancel () {
      this.deleteType = null
      this.deleteStr = ''
      this.deleteGuid = ''

      this.showDeleteDialog = false
    },
    async deleteMapAdminAction() {
      switch(this.deleteType) {
        case 'site':
          this.deleteSiteAction(this.deleteGuid)
          break;
        case 'map':
          this.deleteMapAction(this.deleteGuid)
          break;
        case 'mapimage':
          this.deleteMapImageAction(this.deleteGuid)
          break;
        default:
          this.chgIsAlert({status:true,string:'잘못된 접근 입니다.'})

          break;
      }
    },

    async deleteSiteAction(guid) {
      this.chgIsOverlay(true)
      let reqObj = {}
      reqObj.target = `/site/delete`;
      reqObj.method = "delete";
      reqObj.params = {};
      reqObj.data = {
        "siteGuid": guid
      };
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.getSiteList()
        this.showDeleteDialog = false
      }
      this.chgIsOverlay(false)
    },

    async deleteMapAction(guid) {
      this.chgIsOverlay(true)
      let reqObj = {}
      reqObj.target = `/map/delete`;
      reqObj.method = "delete";
      reqObj.params = {};
      reqObj.data = {
        "mapGuid": guid
      };
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.getMapList()
        this.showDeleteDialog = false
      }
      this.chgIsOverlay(false)
    },

    async deleteMapImageAction(guid) {
      this.chgIsOverlay(true)
      let reqObj = {}
      reqObj.target = `/map/deleteimage`;
      reqObj.method = "delete";
      reqObj.params = {};
      reqObj.data = {
        "mapGuid": guid
      };
      reqObj.headers = { "accept":"application/json" };

      let result = await requestApi(reqObj);
      if(result.msg && result.msg.retStatus && result.msg.retCode === 1000) {
        this.getMapInfo()
        this.showDeleteDialog = false
      }
      this.chgIsOverlay(false)
    },
  }
  
}
</script>
